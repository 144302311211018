<script>
/**
 * name：打印
 * user：sa0ChunLuyu
 * date：2022-04-12 10:58:34
 */
import options from '../../../utils/excel.js'
import ltree from './ltree/ltree';

export default {
  components: {ltree},
  data() {
    return {
      page_options: false,
      pdf_visible: false,
      moban_list: [],
      autotest: true,
      pdf_url: '',
      options: options,
      moban_info: {
        id: 0
      },
    }
  },
  mounted() {
    this.onMounted()
  },
  methods: {
    onMounted() {
      let type = Number(this.$route.query.t) || 0
      let id = Number(this.$route.query.i) || 0
      this.page_options = {
        type,
        id
      }
      this.getMubanList()

      // this.getMubanInfo()
      // this.getDataTypeList()
      // this.getdatalist()
    },
    makeList(list) {
      return list.map((item) => {
        return {
          'id': Number(item.id),
          "type": 1,
          "name": item.name,
          "children": [],
          "content": false
        }
      })
    },
    getMubanList() {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板列表'),
        data: {
          type: this.page_options.type
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = this.makeList(response.data.list)
            this.moban_list = list
            setTimeout(() => {
              this.$refs['ltreeref'].active_arr = [Number(this.page_options.id) || 0]
              if (this.autotest) this.treeCallback([{id: 2}])
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    treeCallback(arr) {
      this.getMubanInfo(arr[0].id)
    },
    getdata() {
      // 打印：获取回填数据
      this.$sa0.post({
        url: this.$api('打印：获取回填数据'),
        data: {
          id: this.page_options.id,
          type: this.page_options.type,
          tpl: this.moban_info.id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            response.data.list.map(item => {
              let range = JSON.parse(item.range)
              luckysheet.setCellValue(range.row[0], range.column[0], {
                m: `${item.value}`,
                v: `${item.value}`,
              })
            })

          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getMubanInfo(id) {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板信息'),
        data: {
          id: id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.moban_info = response.data.info
            if (this.moban_info.table === 1) {
              this.getMubantable(id)
            } else {
              setTimeout(() => {
                this.getMubantable(0)
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getMubantable(id) {
      this.$sa0.post({
        url: this.$api('三个模板：获取模板表格'),
        data: {
          id,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.info
            this.pushExcelJson(table ? {sheets: [JSON.parse(table).data[0]]} : false);
            // if (this.autotest) this.getdata([{id: 1}])
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushExcelJson(exportJson = false) {
      let options = this.options
      if (exportJson) options.data = exportJson.sheets
      luckysheet.destroy();
      luckysheet.create(options);
    },
    getprint() {
      this.$sa0.post({
        url: this.$api('打印：打印'),
        data: {
          content: JSON.stringify(luckysheet.toJson()),
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.pdf_url = response.data.pdf_url
            this.pdf_visible = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onClosePdf() {
      this.pdf_visible = false
    },
  }
}
</script>
<template>
  <div>
    <a-drawer
        title="报告预览"
        width="100%"
        placement="right"
        :visible="pdf_visible"
        @close="onClosePdf"
    >
      <div>
        <iframe :src="pdf_url" frameborder="0" style="width: 100%;height: calc(100vh - 140px);"></iframe>
      </div>
    </a-drawer>


    <a-space align="start">
      <div class="moban_list_wrapper">
        <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="moban_list" all="all1" :all_menu="[]"
               :menu="[]" :callback="treeCallback"></ltree>
      </div>
      <div class="excel_wrapper">
        <div class="excel_table_wrapper">
          <div class="choose_tpl_tip" v-if="moban_info.id === 0">
            请选择模板
          </div>
          <div v-if="moban_info.id !== 0" id="luckysheet" class="luckysheet_wrapper"></div>
        </div>
      </div>
      <div v-if="moban_info.id !== 0" class="button_wrapper">
        <a-space>
          <a-button type="primary" @click="getdata()">读取数据</a-button>
          <a-button type="primary" @click="getprint()">打印</a-button>
        </a-space>
      </div>
    </a-space>
  </div>
</template>
<style>

</style>
<style scoped>
.choose_tpl_tip {
  width: 400px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid skyblue;
}

.luckysheet_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.excel_wrapper {
  position: relative;
  width: 600px;
  height: calc(100vh - 140px);
}

.moban_list_wrapper {
  width: 250px;
}
</style>
